import React, {useState,useEffect} from 'react'
import {Grid,Box} from '@mui/material'
import '../App.css';
// import SocketIOClient from 'socket.io-client'
import { io } from "socket.io-client";
import Fermentador from './Fermentador'
import ResponsiveAppBar from './ResposiveAppBar'
import {Url,usarSocket} from '../Constants/global'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import {Button} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
let link = Url
// let link = "http://192.168.1.11:5000"
// const usarSocket=true

const socket = io(link, {
  transports: ["websocket"],
  cors: {
    origin: "http://localhost:3000/",
  },
});

function Hmi2() {

  
  const [fermentadores,setFermentadores]=useState([])
  
  const [currentFermentador,setCurrentFermentador]=useState({})
  const [indexFer,setIndexFer]=useState(0)

  function ModFechaDescarga(){
    let id =Number(arguments[0])
    let fech=arguments[1]

    let fermentadores2=fermentadores
    console.log("en este momento fermentadores son",fermentadores2)
    let index
    let valFin
    for (index=0; index<fermentadores2.length;index){
      // console.log("tamanho de fers ",fermentadores2.length, " index ", index , " id ",fermentadores2[index]["IdFermentador"])  
      if (fermentadores2[index]["IdFermentador"] === id){
          valFin=index
          break
        }
    }
    fermentadores2[valFin]["LastFechaDescarga"]=fech
    setFermentadores([...fermentadores2])
  }

  function ModVariables(){
    let id =Number(arguments[0])
    let vars=arguments[1]
    
    let fermentadores2=fermentadores
    console.log("en este momento fermentadores son",fermentadores2)
    let index
    let valFin
    for (index=0; index<fermentadores2.length;index){
      // console.log("tamanho de fers ",fermentadores2.length, " index ", index , " id ",fermentadores2[index]["IdFermentador"])  
      if (fermentadores2[index]["IdFermentador"] === id){
          valFin=index
          break
        }
    }
    // fermentadores2[id-1]["LastData"]=vars
    fermentadores2[valFin]["LastData"]=vars
    setFermentadores([...fermentadores2])

    // console.log("Fermentnadores nuevos ",fermentadores)
  }

  function ModInfo(){
    let id = Number(arguments[0])
    let info = arguments[1]
    let fermentadores2=fermentadores
    let index
    
    let valFin
    for (index=0; index<fermentadores2.length;index){
      if (fermentadores2[index]["IdFermentador"] === id){
          valFin=index
          break
        }
    }
    // fermentadores2[id-1]["Info"]=info
    fermentadores2[valFin]["Info"]=info
    setFermentadores([...fermentadores2])
  }
  useEffect(()=>{
    //#100años
    let token="Bearer " + localStorage.getItem("access_token");
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': token }
  };
    fetch(link+"/api/data",requestOptions).then(
      res=>res.json(),e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")
      }
    ).then(
      res=>{
        setFermentadores([...res["Fermentadores"]])
        setCurrentFermentador(res["Fermentadores"][0])
        setIndexFer(0)
        console.log("aqui hmi2  la respuesta ",res)
        return res["Fermentadores"]
      }
    ).then(
      
        fs=>{
          if (usarSocket === true){
          fs.map((i)=>{
            // 🚩 falta alertas xd 
            let topic="Fermentador/"+i?.IdFermentador   
            
            if (true ||i?.Tipo !== "Solo registro"){
              socket.on(topic,(a,b)=>{console.log("llego ",a?.Tipo , a,b)
              if (a?.Tipo === "Info" || a?.Tipo === "Estado" || a?.Tipo === "PE"){
                if (b?.Info !== undefined){
                  // console.log("cambio : ",b?.Info)
                  // ModInfo(i?.IdFermentador,b?.Info)
                  var indexOI=fs.findIndex(xa=> xa?.IdFermentador=== i?.IdFermentador )
                  fs[indexOI]["Info"]=b?.Info
                  // console.log("el cambio es " ,fs)
                  setFermentadores([... fs])
                }
                
              }
              else if (a?.Tipo === "LastData" || a?.Tipo === "Variables" || a?.Tipo === "Variable"    ){
                if (b?.LastData !== undefined){
                  // console.log("cambio : ",b?.LastData)
                  var indexOI=fs.findIndex(xa=> xa?.IdFermentador=== i?.IdFermentador )
                  fs[indexOI]["LastData"]=b?.LastData
                  setFermentadores([... fs])
                }
              }
              else if (a?.Tipo==="Proceso")
              {
                if (b?.LastData !== undefined){
                  var indexOI=fs.findIndex(xa=> xa?.IdFermentador=== i?.IdFermentador )
                  fs[indexOI]["Info"]=b?.Info
                  console.log("el cambio es " ,fs)
                  setFermentadores([... fs])
                }
                if (b?.Info !== undefined){
                  var indexOI=fs.findIndex(xa=> xa?.IdFermentador=== i?.IdFermentador )
                  fs[indexOI]["LastData"]=b?.LastData
                  setFermentadores([... fs])
                }
              }
            })
            }
          })
        }

        }
     
    )


    
    
    
    // socket.on("hola",()=>{
    //     console.log("entro socje")
    // })
    return ()=>{
      console.log("lpt")
      if (usarSocket === true){
      fermentadores.map((i)=>{
        let topic="Fermentador/"+i?.IdFermentador 
        socket.off(topic)
      })
      }
    }
    
  },[]);//eslint-disable-line 
  //arriba falta una referencia del us effct que


  const prevFer= ()=>{
      if (indexFer > 0){
        setCurrentFermentador(fermentadores[indexFer-1])
        setIndexFer(indexFer-1)
      }
  }
  const nextFer= ()=>{
    if (indexFer < fermentadores.length-1){
      setCurrentFermentador(fermentadores[indexFer+1])
        setIndexFer(indexFer+1)
    }
  }
  
  return (
     <div className='bg'>
      <div>
      <ResponsiveAppBar>
        
      </ResponsiveAppBar>
       .
      </div>
      <Box sx={{width: "100%",
        // height: "200vh",
        backgroundColor:"#282c34",
        alignItems: "center",
        justifyContent: "center",
        textAlign:"center",
        color: "white",
        display:"flex",
        flexDirection: 'column'}}>
          <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
          columnSpacing={3}
          >
          
          {/* <Carousel  sx= {{alignItems: "center",
        justifyContent: "center",
        textAlign:"center",
        color: "white"}}>
            {
              fermentadores.map((f,i) => (
                <Fermentador info={f?.Info} 
                       variables={f?.LastData} 
                       key={i}
                       api={link} 
                       IdFermentador={f?.IdFermentador}
                       rendimiento={f?.Rendimiento}
                       CambiarVar={ModVariables} 
                       CambiarInfo={ModInfo}></Fermentador>  
              ))
            }
          </Carousel> */}
          <Grid container
           columnSpacing={3} >
            <Grid item xs={3} md={3} lg={3}>
              <p></p>
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
            <Button  variant="contained" onClick={prevFer}  >
            <ArrowBackIosOutlinedIcon   />
          </Button>
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
            <Button  variant="contained"  onClick={nextFer}>
          <ArrowForwardIosSharpIcon/>
          </Button>
            </Grid>
          </Grid>
          
       
          
          <Fermentador info={currentFermentador?.Info} 
                       variables={currentFermentador?.LastData} 
                       api={link} 
                       IdFermentador={currentFermentador?.IdFermentador}
                       rendimiento={currentFermentador?.Rendimiento}
                       CambiarVar={ModVariables} 
                       CambiarInfo={ModInfo}
                       tipoFer={currentFermentador?.Tipo}
                       CambiarFecha={ModFechaDescarga}
                       FechaDeDescarga={currentFermentador?.LastFechaDescarga}></Fermentador>          
          
        </Grid>
        </Box>
        </div>
  )
}

export default Hmi2