import {Grid,Box, Button,DialogTitle,Dialog,DialogContent} from '@mui/material'
import '../App.css';
import ResponsiveAppBar from './ResposiveAppBar'
import TanqueAlmacen from './TanqueAlmacen'
import {useState,useEffect} from 'react'
import {Url,usarSocket} from '../Constants/global'
import { io } from "socket.io-client";
import Numpad from './Numpad';
let link = Url
let api = Url



const socket = io(link, {
  transports: ["websocket"],
  cors: {
    origin: "http://localhost:3000/",
  },
});

function Almacenamiento() {
    let token="Bearer " + localStorage.getItem("access_token");
    const [tanques,setTanques]=useState([{}])
    const [calibDialog,setCalibDialog]=useState(false)
    const [dilucion,setDilucion]=useState(0)
// fetch al backend para saber la ultima info de los tanques, posterior socket coneccion a los tanques registrados 
    useEffect(() => {
      
      let token="Bearer " + localStorage.getItem("access_token");
      const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': token }
    };

    fetch(link+"/api/tanque",requestOptions).then(
      res=>res.json(),e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")
      }
    ).then(
      res=>{
        setTanques([...res["Tanques"]])
        // setCurrentFermentador(res["Fermentadores"][0])
        // setIndexFer(0)
        console.log("aqui almacenamiento  la respuesta ",res)
        return res["Tanques"]
      }
    ).then(
      tans=>{
        if (usarSocket === true){
          tans.map((i)=>{
            // 🚩 falta alertas xd 
            let topic="Tanque/"+i?.Id 
            socket.on(topic,(cambio,tanque)=>{
              console.log("llego al tanque socket",cambio)
              if (cambio !== undefined){
                var indexOI=tans.findIndex(xa=> xa?.Id=== i?.Id )
                  tans[indexOI]["Contenido"]=cambio?.Contenido
                  setTanques([... tans])
              }
          
              })
          })

          socket.on("Almacenamiento/Dilucion",(Volumen)=>{
            Volumen=Number(Volumen)
            console.log("llego almacenamiento : ",Volumen)
            setDilucion(Volumen)

          })  
      }
    }
    )

      return ()=>{
        console.log("lpt")
      if (usarSocket === true){
      tanques.map((i)=>{
        let topic="Tanque/"+i?.IdFermentador 
        socket.off(topic)
      })
      }
      }
      
    }, [])

    const cancelar=()=>{
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify({ paro:true,calibrar:false})
    };
      fetch(api+"/api/tanque/mqtt/paro",requestOptions
      ).then( res=>res.json(),e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")
      }
    ).then(res=>{
      
  
      // variables=res.variables
      
      console.log("responde  ",res)
      alert(res?.msg)
      
    })
    }
    
    const calibDialogClose=()=>{
        setCalibDialog(false)
    }

    const calibDialogOpen=()=>{
      setCalibDialog(true)
  }

  const calibrarCaudal = (n,b,dat,t)=>{
      
      
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      body: JSON.stringify({ cantidad: n, calibrar:true})
  };
    fetch(api+"/api/tanque/mqtt/calibrar",requestOptions
    ).then( res=>res.json(),e =>{
      console.log("Obtención fallida", e);
      alert("Obtención de datos fallida")
    }
  ).then(res=>{
      
    alert(res?.msg)
    
  })

    calibDialogClose()


  }

  const dialogDiluirComponent =<Dialog
  open={calibDialog}
  onClose={calibDialogClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">
    {"Ingrese cantidad de agua para calibrar"}
  </DialogTitle>
  <DialogContent>
    <Numpad regAlcohol={calibrarCaudal} esAlcohol={false} esTanque={true}></Numpad>
    <Grid container >
    <Grid item xs={12} md={12} lg={12}>.</Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button variant="contained"  fullWidth onClick={calibDialogClose}>
          Cerrar
        </Button>
      </Grid>
    </Grid>
  </DialogContent>
  
</Dialog>
    function regTanque(){
     let id =Number(arguments[0])
     let t=arguments[1]
  

     let tanques2=tanques
     let index
    let valFin
    for (index=0; index<tanques2.length;index){
      // console.log("tamanho de fers ",fermentadores2.length, " index ", index , " id ",fermentadores2[index]["IdFermentador"])  
      if (tanques2[index]["Id"] === id){
          valFin=index
          break
        }
    
      }
     tanques2[valFin]=t
     setTanques([...tanques2])

    }

    return (
        <div className='bg'>
         <div>
         <ResponsiveAppBar>
           
         </ResponsiveAppBar>
          .
         </div>
         <Box sx={{width: "100%",
           // height: "200vh",
           backgroundColor:"#282c34",
           alignItems: "center",
           justifyContent: "center",
           textAlign:"center",
           color: "white",
           display:"flex",
           flexDirection: 'column'}}>
             <Grid container 
             justifyContent="center"
             alignItems="center"
             columnSpacing={4}
             columnGap={4}>
            

              {tanques.map((t)=>{
                return (<TanqueAlmacen key={("T"+t?.Id)} Id={t?.Id} Tipo={t?.Tipo} Acidez={t?.Acidez} Altura={t?.Altura} Volumen={t?.Volumen} Contenido={t?.Contenido} reg={regTanque} Lote={t?.Lote}/>)
              })}
              
              
              
             </Grid>
             <Grid container 
             justifyContent="center"
             alignItems="center"
             columnSpacing={4}
             columnGap={4}>
              <Grid item xs={3} md={3} lg={3}>
                <Button variant="contained"color="warning" fullWidth onClick={calibDialogOpen}> Calibrar </Button>
                {dialogDiluirComponent}
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                {dilucion > 0 && <p>Volumen agregado: {dilucion} L </p>}
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Button variant="contained" fullWidth onClick={cancelar} > Paro Diluir\Calibrar </Button>
              </Grid>
             </Grid>
           </Box>
           </div>
     )



}

export default Almacenamiento