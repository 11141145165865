import {Grid,Box} from '@mui/material'
import '../App.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Url} from '../Constants/global'
import { useLocation } from 'wouter';

function Login() {
    const [, navigate ]= useLocation()
    let link=Url
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          username: data.get('username'),
          password: data.get('password'),
        });

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ username: data.get('username'),password: data.get('password')}),
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               }
        };

        fetch(link+"/login",requestOptions).then(
            
            res=>{
               if (res.status !== 200){
                alert("credenciales invalidos")
               }

               return res.json()
            
        }
        ).then(
            res=> {
                if (res?.access_token != undefined){
                    alert("ingresando")
                    localStorage.setItem("access_token", res?.access_token)
                    navigate('/')
                }
            }
        )

      };

    return (
        <div className='bg'>
         <Box sx={{width: "100%",
           // height: "200vh",
           backgroundColor:"#282c34",
           alignItems: "center",
           justifyContent: "center",
           textAlign:"center",
           color: "white",
           display:"flex",
           flexDirection: 'column'}}>
              <img src={require('../Images/sl.png')} alt="sl" width="400"/>
            

              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1,color: "white" }}
              >
                <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuario"
                name="username"
                autoComplete="username"
                autoFocus
                sx={{ input: { color: 'white' },
                      label:{ color: '#cbd6e2' }}}
                />
                <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ input: { color: 'white' },
                label:{ color: '#cbd6e2' }}}
                />
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                Ingresar
                </Button>
                </Box>
           </Box>
           </div>
     )



}

export default Login