import {Box,Grid} from '@mui/material'
import '../App.css';
import ResponsiveAppBar from './ResposiveAppBar'
import Typography from '@mui/material/Typography';


import AdminAlmacen from './AdminAlmacen';
import AdminFermentador from './AdminFermentador';
import AdminUsuario from './AdminUsuario';

function Admin() {
    let token="Bearer " + localStorage.getItem("access_token");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': token }
  };
    
    return (
        <div className='bg'>
         <div>
         <ResponsiveAppBar>
           
         </ResponsiveAppBar>
          .
         </div>

         <Box sx={{width: "100%",
           // height: "200vh",
           backgroundColor:"#282c34",
           alignItems: "center",
           justifyContent: "center",
           textAlign:"center",
           color: "white",
           display:"flex",
           flexDirection: 'column'}}>
 <Typography variant="h4" gutterBottom>
        Panel Administrador
      </Typography>
      <Typography variant="h5" gutterBottom>
        Usuarios
      </Typography>
      <AdminUsuario/>
      <Typography variant="h5" gutterBottom>
        Fermentadores
      </Typography>
      <AdminFermentador/>
      <Typography variant="h5" gutterBottom>
        Tanques de Almacenamiento
      </Typography>
      <AdminAlmacen/>
           </Box>
           </div>
     )



}

export default Admin