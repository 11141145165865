import {Grid,Box, Button} from '@mui/material'
import {Dialog,DialogTitle,DialogContent,DialogContentText } from '@mui/material'
import '../App.css';
import ResponsiveAppBar from './ResposiveAppBar'
import {useEffect,useState,forwardRef} from 'react'
import { Url } from '../Constants/global';
import { useLocation,Link } from 'wouter';
import CartaFermentador from './CartaFermentador';
import CartaAlmacen from './CartaAlmacen';
import DatePickerDay from "./DatePickerDay"
import Graficador from "./Graficador"
import Slide from '@mui/material/Slide';
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Home() {
    let token="Bearer " + localStorage.getItem("access_token");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': token }
  };
    const [location,navigate]=useLocation()
    const [user,setUser]=useState({})
    const [admin,setAdmin]=useState(false)
    const [dialogGrafica,setDialogoGrafica]=useState(false)
    const [dialogReporte,setDialogoReporte]=useState(false)
    const [jsonGraph,setJsonGraph]=useState({})
    const [dialogPloty,setDialoPloty]=useState(false)
    useEffect(() => {
      
      fetch((Url+"/user"),requestOptions).then(
        res=>{
          console.log(res)
          if (res.status !== 200){
            navigate("/login")
            return false
          }
          else
          {
            return res.json()
          }
        }
      ).then(
        res=>{
          console.log(res)
          if (res?.Tipo === "Admin" ||res?.Tipo === "SuperUser" ){
            setAdmin(true)
          }
          setUser(res)

          console.log("user ", user)
        }
        
      )

    

    }, [])
    const plotyOpen = () => {
      setDialoPloty(true);
    };
  
    const plotyClose=() => {
      setDialoPloty(false);
    };
  
    const openDialogGrafica=()=>{
      setDialogoGrafica(true)
    }
    const closeDialogGrafica=()=>{
      setDialogoGrafica(false)
    }

    const openDialogReporte=()=>{
      setDialogoReporte(true)
    }
    const closeDialogReporte=()=>{
      setDialogoReporte(false)
    }
    function forceDownload(blob, filename,descarga) {
      closeDialogReporte()
      if (descarga === true){
        var a = document.createElement('a');
        let descarga =window.URL.createObjectURL(blob)
        a.download = filename;
        a.href = descarga;
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      else{
        let url = URL.createObjectURL(blob)
        window.open(url)
      }
      
    }
  
    const graficarData=(ap)=>{
      closeDialogGrafica()
      ap["layout"]["autosize"]=true
      console.log("los props son",ap)
      setJsonGraph(ap)
      plotyOpen()
    }
    const dialogGraficaPloty=<Dialog
    fullScreen
    open={dialogPloty}
    onClose={plotyClose}
    TransitionComponent={Transition}
  >
    <Grid container rowSpacing={2}>
    <Grid item xs={12} md={12} lg={12}>
    <Graficador
    plot={jsonGraph} />
    </Grid>

    <Grid item xs={12} md={12} lg={12}>
    <Button autoFocus  fullWidth color="error"  variant="contained" onClick={plotyClose}>
              Cerrar
            </Button>
      </Grid>
    </Grid>
   
    
    </Dialog>
    const dialogoConstGrafica= <Dialog
    open={dialogGrafica}
    onClose={closeDialogGrafica}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    
  >
    <DialogTitle id="alert-dialog-title">
      {"Graficar"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      Seleccione el Fermentador y la fecha o rango de fechas a graficar
      </DialogContentText>
      {<DatePickerDay
      aprobado={graficarData} Reporte={false}/>}
      <Grid container >
      <Grid item xs={12} md={12} lg={12}>.</Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Button variant="contained"  fullWidth onClick={closeDialogGrafica}>
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
    
  </Dialog>

    const dialogoConstReporte= <Dialog
    open={dialogReporte}
    onClose={closeDialogReporte}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    
  >
    <DialogTitle id="alert-dialog-title">
      {"Generar Reporte"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      Seleccione la fecha o rango de fechas para realizar el reporte
      </DialogContentText>
      {<DatePickerDay
      aprobado={forceDownload} Reporte={true}/>}
      <Grid container >
      <Grid item xs={12} md={12} lg={12}>.</Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Button variant="contained"  fullWidth onClick={closeDialogReporte}>
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
    
  </Dialog>
    return (
        <div className='bg'>
         <div>
         <ResponsiveAppBar>
           
         </ResponsiveAppBar>
          .
         </div>
         <Box sx={{width: "100%",
           // height: "200vh",
           backgroundColor:"#282c34",
           alignItems: "center",
           justifyContent: "center",
           textAlign:"center",
           color: "white",
           display:"flex",
           flexDirection: 'column'}}>
          <Grid  container  rowSpacing={10}  >
          <Grid item xs={0} md={1} lg={1}>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
            
              <CartaFermentador/>
            
            </Grid>
            <Grid item xs={0} md={2} lg={2}>

            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              
              <CartaAlmacen/>
              
            </Grid>
                  
                <Grid item xs={4} md={4} lg={4}>
                  {(admin === true) ? (
                  <Button variant="contained"  onClick={openDialogReporte} >
                    Generar Reporte 
                  </Button>) : (<p></p>)}
                  
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Button variant="contained"  onClick={openDialogGrafica} >
                    Ver Grafica
                  </Button>
                  {dialogoConstGrafica}
                  {dialogoConstReporte}
                  {dialogGraficaPloty}
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                {(admin === true) ? (
                  <Link href='admin'>
                  <Button variant="contained"   >
                    Administrar
                  </Button>
                  </Link>) : (<p></p>)}
                </Grid>
            </Grid>
             
           </Box>
           </div>
     )



}

export default Home