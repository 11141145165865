import * as React from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import {Button,Grid,Switch,createTheme,TextField, ThemeProvider,DialogContentText,DialogTitle,Dialog,DialogContent, Typography} from '@mui/material';
import '../css/Fermentador.css';
// import SocketIOClient from 'socket.io-client' //este no 
// import { io } from "socket.io-client";
import {useState,useEffect} from 'react'
import Numpad from './Numpad';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'; //eslint-disable-line
import { TimePicker } from '@mui/x-date-pickers/TimePicker'; //eslint-disable-line
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
const theme = createTheme({
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});



export default function Fermentador({info,IdFermentador,rendimiento,api,variables,CambiarVar,CambiarInfo,tipoFer,CambiarFecha,FechaDeDescarga}) {
    
//                    Sockets
    // const socket = SocketIOClient(api);
    let token="Bearer " + localStorage.getItem("access_token");
    

    

    

    // let topic="Fermentador/"+IdFermentador   
    // const socket = io(api, {
    //   transports: ["websocket"],
    //   cors: {
    //     origin: "http://localhost:3000/",
    //   },
    // });

    // socket.on(topic,(a,b)=>{
    //       console.log("llego info",a,b)
    //       if (a?.Tipo === "Info" || a?.Tipo === "Estado" || a?.Tipo === "PE"){
    //         if (b?.Info !== undefined){
    //           console.log("cambio : ",b?.Info)
    //           CambiarInfo(IdFermentador,b?.Info)
    //         }
            
    //       }
    //       else if (a?.Tipo === "LastData" || a?.Tipo === "Variables" || a?.Tipo === "Variable"    ){
    //         if (b?.LastData !== undefined){
    //           console.log("cambio : ",b?.LastData)
    //           CambiarVar(IdFermentador,b?.LastData)
    //         }
    //       }
    //       else if (a?.Tipo==="Proceso")
    //       {
    //         if (b?.LastData !== undefined){
    //           CambiarInfo(IdFermentador,b?.Info)
    //         }
    //         if (b?.Info !== undefined){
    //           CambiarVar(IdFermentador,b?.LastData)
    //         }
    //       }
    //     })

    
    
    
    const [tipo, settipo] = useState(false)
    const [alcoholDialog, setAlcoholDialog] = useState(false)
    const [acidezDialog, setAcidezDialog] = useState(false)
    const [alcoholRegDialog, setAlcoholRegDialog] = useState(false)
    const [dateDialog, setDateDialog] = useState(false)
    const [date, setDate] = useState(dayjs());


    useEffect(() => {
      let req="opcion_fermentador_"+IdFermentador
      console.log("reqeurimiento ",req)
      if (localStorage.getItem(req) === "true"){
        settipo(true);
      }
      else{
        settipo(false);
      }
    }, [IdFermentador])
    

  const alcoholOpen = () => {
    setAlcoholDialog(true);
  };
  const alcoholClose = () => {
    setAlcoholDialog(false);
  };
  const dateOpen = () => {
    setDateDialog(true);
  };
  const dateClose = () => {
    setDateDialog(false);
  };
  
  const alcoholRegOpen = () => {
    setAlcoholRegDialog(true);
  };
  const alcoholRegClose = () => {
    setAlcoholRegDialog(false);
  };

  const acidezOpen = () => {
    setAcidezDialog(true);
  };
  const acidezClose = () => {
    setAcidezDialog(false);
  };

  const handleChangeTipo = (event) => {
    settipo(event.target.checked);
    localStorage.setItem(("opcion_fermentador_"+IdFermentador), event.target.checked)
    

  };

  const handleChangeDate = (val) => {
    setDate(val);
  };

  let programarCiclo = ()=>{
    console.log("la fecha es ",date.format('DD-MM-YYYY HH:mm:ss'))
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      body: JSON.stringify({ fecha: date.format('DD-MM-YYYY HH:mm:ss')})
  };
    fetch(api+"/api/fermentador/"+IdFermentador+"/auto/tiempo", requestOptions
    ).then(res=>{
      dateClose()
      if (res.status ===200){
        console.log("todo bon")
        return(res.json())
      }
      if (res.status ===204){
        alert("servidor ocupado")
      }
      else{
        console.log(res.json())
        alert("ERROR")
      }
    },e =>{
      console.log("Obtención fallida", e);
      alert("Obtención de datos fallida")}
    
  ).then(res=>{
    if (res?.Msg !== undefined){
      alert(res?.Msg)
    }

    if (res?.Info !== undefined){
      CambiarInfo(IdFermentador,res?.Info)
    }
  }
  )
  }

  let cicloCompleto=() =>{
    fetch(api+"/api/fermentador/"+IdFermentador+"/ciclocompleto",{ method: 'GET',
    headers: {
    "Accept": "application/json",
    'Content-Type': 'application/json',
    'Authorization': token
    }}).then(
   
      res=>{
        if (res.status ===200){
          console.log("todo bon")
          return res.json()
        }
        else if (res.status === 204){
          console.log("alerta de presionado")
          alert("Servidor ocupado")  
          return res
        }
        else{
          console.log(res.json())
          alert("ERROR")
        }
      },e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")}
      
    ).then(res=>{
      console.log(res)
      if (res?.Msg !== undefined){
        alert(res?.Msg)
      }
      
    }
    )
  }

  let motor=(e) => {
    
    fetch(api+"/api/fermentador/"+IdFermentador+"/manual/"+e.target.name,{ method: 'GET',
    headers: {
    "Accept": "application/json",
    'Content-Type': 'application/json',
    'Authorization': token
    }}).then(
   
      res=>{
        if (res.status ===200){
          console.log("todo bon")
          return res.json()
        }
        else if (res.status === 204){
          console.log("alerta de presionado")
          alert("Servidor ocupado")  
          return res
        }
        else{
          console.log(res.json())
          alert("ERROR")
        }
      },e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")}
      
    ).then(res=>{
      if (res?.Msg !== undefined){
        alert(res?.Msg)
      }
    }
    )
    }

  let paroFermentador=() => {
    
    fetch(api+"/api/fermentador/"+IdFermentador+"/parar",{ method: 'GET',
    headers: {
    "Accept": "application/json",
    'Content-Type': 'application/json',
    'Authorization': token
    }}).then(
   
      res=>{
        if (res.status ===200){
          console.log("todo bon")
          return res.json()
        }
        else if (res.status === 204){
          console.log("alerta de presionado")
          alert("Servidor ocupado")  
          return res
        }
        else{
          console.log(res.json())
          alert("ERROR")
        }
      },e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")}
      
    ).then(res=>{
      if (res?.Msg !== undefined){
        alert(res?.Msg)
      }
    })
  }

  let regAcidez = (n,b,dat,t)=>{
    console.log("acidez: ",n ,"obs:",b)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      body: JSON.stringify({ acidez: n,
                            observacion:b,
                            temp:t,
                            date:dat.format('DD-MM-YYYY HH:mm:ss'),})
  };
    fetch(api+"/api/fermentador/"+IdFermentador+"/reg_acidez",requestOptions
    ).then( res=>res.json(),e =>{
      console.log("Obtención fallida", e);
      alert("Obtención de datos fallida")
    }
  ).then(res=>{
    console.log(res.variables)

    // variables=res.variables
    console.log("el nuevo variables es ",res.variables , " y fecha es ", res.fechaDescarga)
    CambiarFecha(IdFermentador,res.fechaDescarga)
    CambiarVar(IdFermentador,res.variables)
  })

    acidezClose()
  }
  let regManAlcohol=(n)=>{
    console.log("alcohol: ",n)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      body: JSON.stringify({ alcohol: n})
  };
    fetch(api+"/api/fermentador/"+IdFermentador+"/reg_alcohol",requestOptions
    ).then( res=>res.json(),e =>{
      console.log("Obtención fallida", e);
      alert("registro fallido")
    }
  ).then(res=>{
    console.log("fetcheando a reg alcohol",res)
    CambiarVar(IdFermentador,res.variables)
  })
    console.log("cerrando el dialog ")
    alcoholRegClose()
  }
//  no Hay resp

  let regAlcohol = (n)=>{
    console.log(n)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      body: JSON.stringify({ alcohol: n})
  };
    fetch(api+"/api/fermentador/"+IdFermentador+"/auto/alcohol",requestOptions
    ).then(res=>{
      if (res.status ===200){
        console.log("todo bon")
        return res.json()
      }
      else if (res.status === 204){
        console.log("alerta de presionado")
        alert("Servidor ocupado")  
        return res.json()
      }
      else{
        console.log(res.json())
        alert("ERROR")
      }
    },e =>{
      console.log("Obtención fallida", e);
      alert("Obtención de datos fallida")}
    
  ).then(res=>{
    console.log(res)
    if (res?.Msg !== undefined){
      alert(res?.Msg)
    }
    
  }
  )
    alcoholClose()
  }
  
  
  const dialogAlcoholComponent =<Dialog
  open={alcoholDialog}
  onClose={alcoholClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  
>
  <DialogTitle id="alert-dialog-title">
    {"Concentracion Alcoholica"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
     Seleccione el % de concetracion alcohilca para descarga
    </DialogContentText>
    <Numpad regAlcohol={regAlcohol} esAlcohol={true} esTanque={false}></Numpad>
    <Grid container >
    <Grid item xs={12} md={12} lg={12}>.</Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button variant="contained"  fullWidth onClick={alcoholClose}>
          Cerrar
        </Button>
      </Grid>
    </Grid>
  </DialogContent>
  
</Dialog>



const dialogAcidezComponent =<Dialog
  open={acidezDialog}
  onClose={acidezClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  
>
  <DialogTitle id="alert-dialog-title">
    {"Registro de acidez"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
     Seleccione el % de acido acetico para registrar
    </DialogContentText>
    <Numpad regAlcohol={regAcidez} esAlcohol={false} esTanque={false}></Numpad>
    <Grid container >
    <Grid item xs={12} md={12} lg={12}>.</Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button variant="contained"  fullWidth onClick={acidezClose}>
          Cerrar
        </Button>
      </Grid>
    </Grid>
  </DialogContent>
  
</Dialog>


const dialogAlcoholRegComponent =<Dialog
  open={alcoholRegDialog}
  onClose={alcoholRegClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  
>
  <DialogTitle id="alert-dialog-title">
    {"Registro manual de alcohol"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
     Seleccione el % de Concentracion alcoholica registrar
    </DialogContentText>
    <Numpad regAlcohol={regManAlcohol} esAlcohol={true} esTanque={false}></Numpad>
    <Grid container >
    <Grid item xs={12} md={12} lg={12}>.</Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button variant="contained"  fullWidth onClick={alcoholRegClose}>
          Cerrar
        </Button>
      </Grid>
    </Grid>
  </DialogContent>
  
</Dialog>

const dialogDateComponent =<Dialog
        open={dateDialog}
        onClose={dateClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

        >
        <DialogTitle id="alert-dialog-title">
          {"Programar ciclo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Seleccione la fecha para la descarga
          </DialogContentText>
          <Grid container 

          rowSpacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              `
            </Grid>
          <Grid item xs={12} md={12} lg={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                    label="Seleccionar Fecha y Hora"
                    value={date}
                    onChange={handleChangeDate}
                    renderInput={(params) => <TextField {...params} />}
                  />

            {/* <TimePicker
              label="Seleccionar Fecha y Hora"
              value={date}
              onChange={handleChangeDate}
              renderInput={(params) => <TextField {...params} />}
            /> */}
          </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
              <Button variant="contained"  color="success" fullWidth onClick={programarCiclo}>
                Set
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Button variant="contained"  fullWidth onClick={dateClose}>
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        </Dialog>

  let botoneriaAutomatica= <ThemeProvider theme={theme}> 
  <Grid item xs={12} md={12} lg={12} >
      <Button variant="contained"  fullWidth onClick={alcoholOpen} >
      Automatico Gl%
      </Button>
      {dialogAlcoholComponent}
      
  </Grid>
  <Grid item xs={12} md={12} lg={12}>
      <Button variant="contained"  fullWidth onClick={dateOpen}>
      Programar Ciclo
      </Button>
      {dialogDateComponent}

  </Grid>
  </ThemeProvider>
  let botoneriaManual =                   <ThemeProvider theme={theme}> 
    <Grid item xs={12} md={12} lg={12} >
        <Button variant="contained" name="carga"  color="neutral" fullWidth onClick={motor} >
            Cargar 
        </Button>
    </Grid>
    <Grid item xs={12} md={12} lg={12}>
        <Button variant="contained" name="descarga" color="neutral" fullWidth onClick={motor}>
        Descargar 
        </Button>
    </Grid>
    <Grid item xs={12} md={12} lg={12}>
    {(tipoFer!== "Manual" )?  (
        <Button variant="contained" color="neutral" fullWidth onClick={cicloCompleto}>
        Ciclo completo    
        </Button>):(<p></p>)}
    </Grid></ThemeProvider>
    
    
  let botoneria=null
  if (tipo ){
    botoneria=botoneriaAutomatica
  }
  else{
    botoneria=botoneriaManual
  }

  return (
    <div>
    
    <header className='head' ><h1>Fermentador {IdFermentador}</h1></header>  
    {(variables?.Temperatura > info?.TempRef)?(<h2 style={{color:"red"}}> Temperatura Elevada!</h2>):(<p></p>)}
    
    <Box sx={{ display: 'flex' , alignItems:"center" ,
                justifyContent: "center",backgroundColor:"inherit",color:"inherit",
                boxShadow:"none"}}   >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={4}
          columnSpacing={10}
          > 
          <Grid item xs={12} md={3} lg={3} >
          {/* <FormGroup >
            <FormControlLabel control={<Switch checked={tipo}  onChange={handleChange} />} label="Label" />
          </FormGroup> */}
                {(tipoFer=== "Automatico completo" || tipoFer=== "Automatico sin alcohol" )?  (
                <div>
                <p>{(tipo=== true ) ? ("Automatico"):("Manual")} </p>
                <Switch checked={tipo}  onChange={handleChangeTipo} > </Switch>
                </div>
                ):(<p></p>) }   
                <Grid  container  rowSpacing={1}  >  
                  { (tipoFer!== "Solo registro" )? (botoneria) : (<p></p>)}
                  <Grid item xs={12} md={12} lg={12}>
                  <Button variant="contained" color="success" fullWidth onClick={alcoholRegOpen}>
                    Registro Manual de Alcohol  
                    </Button>
                    {dialogAlcoholRegComponent}
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Button variant="contained" color="warning" fullWidth onClick={acidezOpen}>
                    Registro de Acidez   
                    </Button>
                    
                    {dialogAcidezComponent}
                  </Grid>
                  

                </Grid>
            </Grid>
            
            

        
        <Grid item xs={12} md={3} lg={3} sx={{alignItems:"center" ,display: 'flex',
                justifyContent: "center"}} >
            <div className='superContairner' >
                <div className='containerFermentador'> 
                <img src={require('../Images/Fermentador.png')} alt="fermen" width="230"/>
                    <div className='centered-fer'>
                                <Box>
                                <Box sx={{ borderRadius: '10px',m: 1,backgroundColor:"#504f4f" }} >
                                  <Typography  variant="h6" sx={{ fontWeight: 200}} > Alcohol: {variables?.Alcohol}°Gl</Typography>
                                </Box>
                                
                                  {/* <Typography  variant="h6" sx={{ fontWeight: 200}} > {variables?.Alcohol}°Gl</Typography> */}
                                
                                <Box sx={{ borderRadius: '14px',m: 1,backgroundColor:"#504f4f" }} >
                                  <Typography  variant="h6" sx={{ fontWeight: 200}} > Temp: {variables?.Temperatura}°C</Typography>
                                </Box>
                                
                                
                                  {/* <Typography  variant="h6" sx={{ fontWeight: 200}} > {variables?.Temperatura}°C</Typography> */}
                                
                                <Box sx={{ borderRadius: '14px',m: 1,backgroundColor:"#504f4f" }} >
                                  <Typography  variant="h6" sx={{ fontWeight: 200}} > Acidez: {variables?.Acidez}%</Typography>
                                </Box>
                                </Box>
                                  {/* <Typography  variant="h6" sx={{ fontWeight: 200}} > {variables?.Acidez}%</Typography> */}
                                
                    </div>
                    <p> Estado : {info?.Estado}</p>
                    <p> Rendimiento:{rendimiento?.Valor}  </p> 
                    {(FechaDeDescarga !== null && FechaDeDescarga !== undefined  ) ? (<p> Proxima descarga:{FechaDeDescarga}  </p> ):(<p></p>)}
                </div> 
                {(tipoFer=== "Automatico completo" || tipoFer=== "Automatico sin alcohol" )?  (
                <div className ="tanqueContairner">
                    <div className='tanqueCarga'>
                      <img src={require((info?.TanqueCarga=== true ) ? ("../Images/TanqueLLeno.png"):("../Images/Tanque.png"))} alt="tanque carga" width="100"/>
                      <p id="labelP">Carga</p>
                    </div>
                    <div className='tanqueDescarga'>
                      <img src={require((info?.TanqueDescarga=== true ) ? ("../Images/TanqueLLeno.png"):("../Images/Tanque.png"))} alt="tanque descarga" width="100"/>
                      <p id="labelP">Descarga</p>
                    </div>
                </div>) : (<p></p>)}

                </div>

        </Grid>
        
        <Grid item xs={12} md={3} lg={3} >
            <CardContent sx={{ flex: '1 0 auto' }}>
                <Grid  container  rowSpacing={1}  >        
                    <Grid item xs={12} md={12} lg={12}  >
                    {(tipoFer!== "Solo registro" )?  (
                        <Button variant="contained" color="error"  size="large"   fullWidth  onClick={paroFermentador}>
                        Parar 
                        </Button>):(<p></p>)}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                    {(tipoFer!== "Solo registro" )?  (
                        <div>
                        <p> Valvula Carga : {info?.ValCar} </p>
                        <p> Valvula Descarga: {info?.ValDes} </p>
                        <p> Motor Carga: {(info?.MotorCarga=== true ) ? ("Encendido"):("Apagado")} </p>
                        <p> Motor Descarga: {(info?.MotorDescarga=== true ) ? ("Encendido"):("Apagado")}</p>
                        </div>):(<p></p>)}
                        {(tipoFer=== "Automatico completo" || tipoFer=== "Automatico sin alcohol" )?  (
                        <div><p> Tanque Carga:    {(info?.TanqueCarga=== true ) ? ("Lleno"):("Vacio")}</p>
                        <p> Tanque Descarga: {(info?.TanqueDescarga=== true ) ? ("LLeno"):("Vacio")}</p></div>):(<p></p>)}
                    </Grid>
                </Grid>
            </CardContent>
            </Grid>
         </Grid>         
    </Box>
    </div>
  );
}