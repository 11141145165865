import * as React from 'react';
import Box from '@mui/material/Box';
import {useState} from 'react'
import {Button,Grid,TextField} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'; //eslint-disable-line
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Numpad({regAlcohol,esAlcohol,esTanque}) {
    const [registro, setRegistro] = React.useState("Carga");
    const [date, setDate] = useState(dayjs());
    const [temp,setTemp]=useState(25.0)
    const [fueraTiempo,setFueraTiempo]=useState(false)


    const handleChangeDate = (val) => {
        setDate(val);
        setFueraTiempo(true)
        
      };
    const cambioTipoRegistro = (event) => {
        setRegistro(event.target.value);
      };

    let nums = Array(9)
    .fill()
    .map((num, i) => i+1);
    const [concetracion,setConcentracion]=useState("")
    const click=(e)=>{
        // console.log (e.target.name,concetracion.length )
        if (e.target.name === "."){
            if (concetracion.includes('.')){
                console.log("error ya hay punto ")
            }
            else {
                setConcentracion(concetracion.concat(e.target.name))    
            }

        }
        else{
            if (concetracion.length>0 & concetracion.length<4)
            {
                
                if (e.target.name === "0" & !(concetracion.includes('.')) & (concetracion[0]===('0')) ){
                    console.log("no puede haber doble cero")
                }
                else {
                    setConcentracion(concetracion.concat(e.target.name))    
                }
            }
            if (concetracion.length===0){
                console.log("es 0 y el name es ")
                if (e.target.name> 1 & esAlcohol){
                    console.log("no")
                }
                else{
                    setConcentracion(concetracion.concat(e.target.name))    
                }
                
            }
            if (concetracion.length >= 4){
                console.log("muchho numero ")
            }
        }
        
        
        
    }
    const borrar=()=>{
        setConcentracion(concetracion.slice(0,-1 ))
    }
    const clear=()=>{
        setConcentracion("")
    }
    

return (
    <Box sx={{ display: 'flex' , alignItems:"center" ,
    justifyContent: "center",backgroundColor:"inherit",color:"inherit",
    boxShadow:"none"}}>
        
        
        
          
          
            <Grid
            container
            // justifyContent="center"
            justify="flex-end"
            alignItems="center"
            rowSpacing={4}
            
            columnSpacing={2}
            > 
            
                {/* <Grid item xs={12} md={12} lg={12} > 
                    Seleccione el valor de concentracion alcoholica
                </Grid> */}
                {esTanque===false && esAlcohol !== true && <Grid item xs={12} md={12} lg={12} > <p>Seleccione la fecha de registro</p>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                    label="Seleccionar Fecha y Hora"
                    value={date}
                    onChange={handleChangeDate}
                    disableFuture
                    minDate={new Date('2017')}
                    renderInput={(params) => <TextField {...params} fullWidth/>}
                  />
                  {fueraTiempo=== true && <TextField value={temp} type="number"   InputProps={{ inputProps: { min: -10.0, max: 50.0 } }} onChange={(event) => {
          setTemp(event.target.value);
        }}  label="Seleccionar temperatura" />}

          </LocalizationProvider>
                 </Grid>  }
                <Grid item xs={12} md={12} lg={12} > 
                    <TextField  required
                    id="outlined-required"
                    label="Concentracion %"
                    value={concetracion}
                    type="number"
                    autoFocus
                    // inputProps={{ inputMode: 'numeric' }}
                    onKeyDown={(event)=>{
                        // console.log( "tecla presionada es ",event.key)
                        let formato={target:{name:event.key}}
                        if (Number(event.key)){
                            click(formato)
                        }
                        else if (event.key === "Backspace"){
                            borrar()
                        }
                        else if (event.key === "."){
                            click(formato)

                        }
                        
                    }}
                    
                    // onChange={(event) => {
                    //     if (Number(event.target.value)){
                    //         setConcentracion(event.target.value);
                    //     }
                        
                        
                    //   }}
                    fullWidth variant="outlined"
                    
                     />
                         
                </Grid>
                {nums.map((num) => (
                <Grid item xs={4} md={4} lg={4} key={num + esAlcohol} >
                <Button
                    shape="round"
                    
                    name={num}
                    fullWidth
                    variant="contained" 
                    color="warning"
                    onClick={click}
                    sx={{borderRadius:"300px"}}
                >
                    {num}
                </Button>
                </Grid>
                ))}
                <Grid item xs={4} md={4} lg={4} >       
                            <Button sx={{borderRadius:"300px"}} variant="contained" name="." color="warning" fullWidth onClick={click} >
                                .
                            </Button>
                </Grid>
                <Grid item xs={4} md={4} lg={4} >       
                            <Button sx={{borderRadius:"300px"}} variant="contained" name="0" color="warning" fullWidth onClick={click} >
                                0
                            </Button>
                </Grid>
                <Grid item xs={4} md={4} lg={4} >       
                            <Button sx={{borderRadius:"300px"}} variant="contained"  fullWidth onClick={borrar} >
                                x
                            </Button>
                </Grid>
                { esTanque===false && esAlcohol !== true  &&
                <Grid item xs={12} md={12} lg={12} > 
                <FormControl fullWidth>
                    <InputLabel >Registro</InputLabel>
                    <Select
                    
                    value={registro}
                    label="Tipo de registro"
                    onChange={cambioTipoRegistro}
                    >
                    <MenuItem value={"Control"}>Control</MenuItem>
                    <MenuItem value={"Carga"}>Carga</MenuItem>
                    <MenuItem value={"Descarga"}>Descarga</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
                }
                

                <Grid item xs={4} md={4} lg={4} >       
                            <Button sx={{borderRadius:"300px"}} variant="contained" color="error"  fullWidth onClick={clear} >
                                Borrar
                            </Button>
                </Grid>
                <Grid item xs={4} md={4} lg={4} > </Grid>
                <Grid item xs={4} md={4} lg={4} >  

                            <Button sx={{borderRadius:"300px"}} variant="contained" color={(esAlcohol=== true ) ? ("success"):("warning")}  fullWidth onClick={
                                (esAlcohol=== true ) ? (event => regAlcohol(concetracion)):(event => regAlcohol(concetracion,registro,date,temp))} >
                                {(esAlcohol=== true ) ? ("Set"):("Registrar")}
                            </Button>
                </Grid>
                
                        
                    
    
            </Grid>
          
    </Box>
);
}