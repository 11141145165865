
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import {Box} from '@mui/material';
import '../App.css';
function Graficador({plot})  {
    
       
    return (
      <div style={{textAlign:"center" }} >
      <h2>Grafica</h2>
      <div className='plottyApp'>
      <Plot  style={{ width: '99%', height: '99%' }} data={plot.data} layout={plot.layout}/>
      </div>
      
      </div>
    );
};

export default Graficador;