import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'wouter';
import '../App.css';
export default function CartaFermentador() {
  

  return (
    <Link href='/fermentador'>
    <Card  variant="outlined" sx={{ display: 'flex' ,backgroundColor:"#fef1d8"}}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            Fermentacion
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Supervicion de  Temperatura
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Programacion de Ciclo de Carga Descarga
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Registro de Acidez
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Supervicion de Grado Alcoholico
          </Typography>

        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={require("../Images/Fermentador.png")}
        alt="Live from space album cover"
      />
    </Card>
    </Link>
  );
}