
import './App.css';
// import Hmi1 from './Components/Hmi1'
import Hmi2 from './Components/Hmi2'
import Home  from './Components/Home';
import Login from './Components/Login';
import Almacenamiento from './Components/Almacenamiento';
import defaultPage from './Components/defaultPage'
import Admin from './Components/Admin';
// import Fermentador from './Components/Fermentador'
import {Switch ,Route } from "wouter";

function App() {
  
  return (
  <Switch>
  <Route path="/" component={Home} />
  <Route path="/fermentador" component={Hmi2} />
  <Route path="/login" component={Login} />
  <Route path="/almacenamiento" component={Almacenamiento} />
  <Route path="/settings" component={Hmi2} />
  <Route path="/admin" component={Admin} />
  <Route path="/:rest*" component={defaultPage}></Route>
  </Switch>
  )
}

export default App
