import {Box,Grid} from '@mui/material'
import '../App.css';
import { useEffect ,useState} from 'react';
import { Url } from '../Constants/global';
import { useLocation } from 'wouter';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

export default function AdminUsuario(){
    let token="Bearer " + localStorage.getItem("access_token");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': token }
  };
    const [,navegate]=useLocation()
    const [users,setUsers]=useState([])
    const [addUser,setAddUser]=useState(false)
    const [editUser,setEditUser]=useState(false)
    const [userToEdit,setUserToEdit]=useState("")
    useEffect(() => {
      fetch((Url+"/api/getusers"),requestOptions).then(
        res=>{
            if (res.status === 205){
              navegate("/")
            }
            return res.json()
        }
      ).then(
            res=>{
                console.log(res)
                setUsers(res["Usuarios"])
            }
      )
    
    }, []) //eslint-disable-line 
  
    function deleteUser(e){
      if (window.confirm("Esta seguro de eliminar el usuario " + e.target.name) ){
        
        
        let postContent=JSON.stringify({
          username:  e.target.name
        });
        let requestOptions2 = {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', 'Authorization': token },
          body:postContent
      };
      fetch((Url+"/user"),requestOptions2).then(
        res=>{
          if (res.status === 201){
            window.location.reload(false);
          }
          return res.json();
        }

      ).then(
          res=>{
            alert(res?.msg)
          }
      )
      }

      
    }
    
    const Edit = (event)=>{
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let postContent=JSON.stringify({
        username:userToEdit,
        password:data.get('password'),
        tipo:data.get('type'),
        nombre:data.get('name'),
        apellido:data.get('lastname'),
      });
      let requestOptions2 = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body:postContent
    };
      // fechear
      fetch((Url+"/user"),requestOptions2).then(
        res=>{
          if (res.status ===201){
            
            handleEditUserClose()
            
          }

          return res.json();
        }

      ).then(
          res=>{
            alert(res?.msg)
          }
      )
      

    }
    const User = (event)=>{
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let postContent=JSON.stringify({
        username: data.get('username'),
        password: data.get('password'),
        tipo:data.get('type'),
        nombre:data.get('name'),
        apellido:data.get('lastname'),
      });
      
      let requestOptions2 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body:postContent
    };
      // fechear
      fetch((Url+"/user"),requestOptions2).then(
        res=>{
          if (res.status ===201){
            
            handleAddUserClose()
            
          }

          return res.json();
        }

      ).then(
          res=>{
            alert(res?.msg)
          }
      )
      // alert("agregando "+ data.get('username'))
    }
    const handleAddUserOpen = () => {
      setAddUser(true);
    };
  
    const handleAddUserClose = () => {
      setAddUser(false);
    };
    const handleEditUserOpen = (e) => {
      setUserToEdit (e.target.name)
      setEditUser(true);
      
    };
  
    const handleEditUserClose = () => {
      setEditUser(false);
    };
    const addUserPopUp= <Dialog open={addUser} onClose={handleAddUserClose}>
    <Box component="form" onSubmit={User} noValidate>
    <DialogTitle>Agregar Usuario</DialogTitle>
    <DialogContent>
      <DialogContentText>
       Llenar los datos para crear un nuevo usuario
      </DialogContentText>
        <TextField autoFocus required margin="dense" name="username" id="username"label="Nombre de usuario" type="name" fullWidth variant="standard"/>
        <TextField required  margin="dense" name="password"  label="Contraseña" fullWidth variant="standard"/>
        <TextField required  select name="type" id="type" defaultValue="Regular" label="Tipo de usuario"  helperText="Seleccione el tipo de usuario" fullWidth variant="standard">
          <MenuItem  value="Regular">
                Regular
          </MenuItem>
          <MenuItem  value="Admin">
                Admin
          </MenuItem>
          <MenuItem  value="HMI">
                HMI
          </MenuItem>
        </TextField>

        <TextField  margin="dense" name="name" id="name" label="Nombre" type="name" fullWidth variant="standard"/>
        <TextField  margin="dense" name="lastname" id="lastname" label="Apellidos" type="name" fullWidth variant="standard"/>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleAddUserClose}>Cancel</Button>
      <Button type="submit">Agregar</Button>
    </DialogActions>
    </Box>
  </Dialog>
  
    const editUserPopUp=<Dialog open={editUser} onClose={handleEditUserClose}>
    <Box component="form" onSubmit={Edit} noValidate>
    <DialogTitle>Editar Usuario</DialogTitle>
    <DialogContent>
      <DialogContentText>
       Llenar los datos para editar el usuario {userToEdit}
      </DialogContentText>
        <TextField margin="dense" name="password" id="password" label="Contraseña" fullWidth variant="standard"/>
        <TextField select name="type" id="type" label="Tipo de usuario"  helperText="Seleccione el tipo de usuario" fullWidth variant="standard">
          <MenuItem  value="Regular">
                Regular
          </MenuItem>
          <MenuItem  value="Admin">
                Admin
          </MenuItem>
          <MenuItem  value="HMI">
                HMI
          </MenuItem>
        </TextField>

        <TextField  margin="dense" name="name" id="name" label="Nombre" type="name" fullWidth variant="standard"/>
        <TextField  margin="dense" name="lastname" id="lastname" label="Apellidos" type="name" fullWidth variant="standard"/>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleEditUserClose}>Cancel</Button>
      <Button type="submit">Modificar</Button>
    </DialogActions>
    </Box>
  </Dialog>

  return (
    <>
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Nombre de usuario</TableCell>
                    <TableCell align="left">Tipo de usuario</TableCell>
                    <TableCell align="left">Nombre</TableCell>
                    <TableCell align="left">Apellidos</TableCell>
                    <TableCell align="left">Editar</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {users.map((user) => (
                
                <TableRow
                key={user?.Id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row"> {user?.User} </TableCell>
                  <TableCell align="left">{user?.Tipo}</TableCell>
                  <TableCell align="left">{user?.Nombre}</TableCell>
                  <TableCell align="left">{user?.Apellido}</TableCell>
                  <TableCell align="left">
                      <Button variant="outlined" name={user?.User} onClick={handleEditUserOpen}>Edit</Button> 
                      <b></b>
                      <Button onClick={deleteUser} name={user?.User}  color="error" variant="contained">Delete</Button>
                      
                  </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
      </TableContainer>
        {addUserPopUp}
        {editUserPopUp}
      <Grid Container rowSpacing={10}>
        <Grid item xs={12} md={12} lg={12}>
          <p></p>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
              <Button variant="contained"  color="success"  onClick={handleAddUserOpen}> Agregar Usuario</Button>
        </Grid>
      </Grid>
      </>
  )
}

