import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import '../App.css';
import { Link } from 'wouter';
export default function CartaAlmacen() {
  

  return (
    <Link href='/almacenamiento'>

    <Card  variant="outlined" sx={{ display: 'flex' ,backgroundColor:"#fef1d8"}}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            Almacenamiento
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Supervicion de stock
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Registro de produccion 
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Estimacion de agua para regulacion
          </Typography>


        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 181 ,
              height:260}}
        image={require("../Images/Tanque.png")}
        alt="LTanque"
      />
    </Card>
    </Link>
  );
}