import {Box,Grid, Typography,Switch} from '@mui/material'
import '../App.css';
import { useEffect ,useState} from 'react';
import { Url } from '../Constants/global';
import { useLocation } from 'wouter';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';


export default function AdminFermentador(){
    let token="Bearer " + localStorage.getItem("access_token");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': token }
  };
  const [,navegate]=useLocation()
    const [fermentadores,setFermentadores]=useState([])
    const [rasps,setRasps]=useState([])
    const [addFermentador,setAddFermentador]=useState(false)
    const [tipoFermentador,setTipoFermentador]=useState("Solo registro")
    const [raspId,setRaspId]=useState(1)
    const [errorEntrada,setErrorEntrada]=useState(false)
    const [errorSalida,setErrorSalida]=useState(false)
    const [errorPWM,setErrorPWM]=useState(false)
    const [errorADS,setErrorADS]=useState(false)
    const [tempRef,setTempRefVal]=useState(33.0)
    const [TempAds,setTempAds]=useState(false)
    
    useEffect(() => {
      fetch((Url+"/api/getfermentadores"),requestOptions).then(
        res=>{
            if (res.status === 205){
              navegate("/")
            }
            else if (res.status === 200){
              return res.json()
            }
            
        }
      ).then(
            res=>{
              console.log("desde adminfermentador fers" ,res["Fermentadores"])
                setFermentadores(res["Fermentadores"])
            }
      )
            
      fetch((Url+"/api/getraspberrys"),requestOptions).then(
        res=>{
            if (res.status === 205){
              navegate("/")
            }
            console.log("adRasp",res)
            return res.json()
        }
      ).then(
            res=>{
                console.log("desde adminfermentador",res["Rasps"])
                setRasps(res["Rasps"])
            }
      ) 
    }, []) //eslint-disable-line 
  
    function deleteFermentador(e){
      if (window.confirm("Esta seguro de eliminar el fermentador " + e.target.name) ){
        
        // fechear
        let postContent=JSON.stringify({
          Id:  e.target.name
        });
        let requestOptions2 = {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', 'Authorization': token },
          body:postContent
      };
      fetch((Url+"/api/fermentador"),requestOptions2).then(
        res=>{
          if (res.status === 201){
            window.location.reload(false);
          }
          return res.json();
        }

      ).then(
          res=>{
            alert(res?.msg)
          }
      )
      }

      
    }
    
    const calibrateMQ3= (e)=>{
      console.log("Calibrate")
    }
    const setTempRef = (e)=>{
      // console.log("TARGET NAME ->",(e.target.name))
      fetch((Url+"/api/fermentador/"+e.target.name+"/tempref/"+tempRef),requestOptions).then(
        res=>{
          return res.json()
        }
      ).then(
        r=>{
          console.log("msg - > ",r)
          alert(r?.msg)
          if (r?.msg==="Temperatura actualizada correctamente"){
            let i=fermentadores.findIndex(x => x.id ===Number(e.target.name));
            let fermentadores2;
            fermentadores2=fermentadores;
            fermentadores2[i]["TempRef"]=tempRef;
            setFermentadores([...fermentadores2])
          }
        }
        
      )
    }

    const cambiarTipo= (e)=>{
      setTipoFermentador(e.target.value)
    }
    const FermentadorSubmit = (event)=>{
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let das={
        Id: Number(data.get('Id')),
        ValCar:[Number(data.get('VC1')),Number(data.get('VC2')),Number(data.get('VC3'))],
        ValDes:[Number(data.get('VD1')),Number(data.get('VD2')),Number(data.get('VD3'))],
        TanqueCarga:Number(data.get('TC')),
        TanqueDescarga:Number(data.get('TD')),
        MotorCarga:Number(data.get('MC')),
        MotorDescarga:Number(data.get('MD')),
        Alcohol:Number(data.get('AL')),
        Temperatura:data.get('TEMP'),
        RaspId:Number(data.get('RaspId')),
        Vali:true,
        Type_Temp:(TempAds)?("ADS"):("1WIRE")
      }
      
      // fechear
      if (tipoFermentador === "Solo registro"){
        das.ValCar=[999,999,999]
        das.ValDes=[999,999,999]
        das.MotorDescarga=999
        das.MotorCarga=999
        das.TanqueCarga=999
        das.TanqueDescarga=999
        das.Alcohol=999
        setErrorADS(false)
        setErrorEntrada(false)
        setErrorPWM(false)
        setErrorSalida(false)
      }
      else if (tipoFermentador === "Manual"){
        das.TanqueCarga=999
        das.TanqueDescarga=999
        das.Alcohol=999
        if (das.ValCar[0] === das.ValDes[0]){
          setErrorPWM(true)
        }
        else{
          setErrorPWM(false)
        }
        if (das.ValCar[1] === das.ValDes[1] || das.ValCar[1] === das.ValDes[2] || das.ValCar[1] === das.ValCar[2] ||  das.ValDes[2]=== das.ValDes[1]){
            setErrorEntrada(true)
        }
        else{
          setErrorEntrada(false)
        }
        if (das.MotorCarga === das.MotorDescarga){
          setErrorSalida(true)

        }
        else{
          setErrorSalida(false)
        }
        setErrorADS(false)

      }
      else if (tipoFermentador === "Automatico sin alcohol"){
        
        das.Alcohol=999
        if (das.ValCar[0] === das.ValDes[0]){
          setErrorPWM(true)
        }
        else{
          setErrorPWM(false)
        }
        if (das.ValCar[1] === das.ValDes[1] || das.ValCar[1] === das.ValDes[2] || das.ValCar[1] === das.ValCar[2] ||  das.ValDes[2]=== das.ValDes[1]){
            setErrorEntrada(true)
        }
        else{
          setErrorEntrada(false)
        }
        if (das.MotorCarga === das.MotorDescarga){
          setErrorSalida(true)

        }
        else{
          setErrorSalida(false)
        }
        if (das.TanqueCarga === das.TanqueDescarga) {
          setErrorADS(true)
        }
        else{
          setErrorADS(false)
        }
      }
      else{
        if (das.ValCar[0] === das.ValDes[0]){
          setErrorPWM(true)
        }
        else{
          setErrorPWM(false)
        }
        if (das.ValCar[1] === das.ValDes[1] || das.ValCar[1] === das.ValDes[2] || das.ValCar[1] === das.ValCar[2] ||  das.ValDes[2]=== das.ValDes[1]){
            setErrorEntrada(true)
        }
        else{
          setErrorEntrada(false)
        }
        if (das.MotorCarga === das.MotorDescarga){
          setErrorSalida(true)

        }
        else{
          setErrorSalida(false)
        }
        if (das.TanqueCarga === das.TanqueDescarga || das.TanqueCarga === das.Alcohol || das.TanqueDescarga === das.Alcohol) {
          setErrorADS(true)
        }
        else{
          setErrorADS(false)
        }
      }

      let postContent=JSON.stringify(das);
      console.log(postContent)

      if (errorADS === false && errorEntrada === false && errorPWM === false && errorSalida === false  ){
        let requestOptions2 = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': token },
          body:postContent
      };
          fetch((Url+"/api/fermentador"),requestOptions2).then(
            res=>{
              return res.json();
            }
    
          ).then(
              res=>{
                alert(res?.msg)
              }
          
            )
      }
      
    }
    const handleaddFermentadorOpen = () => {
      setAddFermentador(true);
    };
  
    const handleaddFermentadorClose = () => {
      setAddFermentador(false);
    };

    const idRaspset=(e)=>{
      setRaspId(e.target.value)
    }


    const textManual =       <Grid container >
      <Grid item xs={12} md={12} lg={12}>
        <p>ingrese los pines de la valvula de carga</p>
      </Grid> 
    <Grid item xs={4} md={4} lg={4}>
    <TextField required label="PWM" type="number" error={errorPWM} name="VC1" select  fullWidth >
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.PinesLibres?.PWM["1"].map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
    </Grid>
    <Grid item xs={4} md={4} lg={4}>
    <TextField required label="Pin abierto" error={errorEntrada} name="VC2" select  fullWidth> 
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.PinesLibres?.Entrada["1"].map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
    </TextField>
    </Grid>
    <Grid item xs={4} md={4} lg={4} >
    <TextField required label="Pin cerrado" name="VC3" error={errorEntrada} select  fullWidth>
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.PinesLibres?.Entrada["1"].map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
    </Grid>
    <Grid item xs={12} md={12} lg={12}>
        <p>ingrese los pines de la valvula de descarga</p>
      </Grid> 
    <Grid item xs={4} md={4} lg={4}>
    <TextField required label="PWM" name="VD1"  error={errorPWM} select  fullWidth >
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.PinesLibres?.PWM["1"].map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
    </Grid>
    <Grid item xs={4} md={4} lg={4}>
    <TextField required label="Pin abierto" name="VD2"  error={errorEntrada} select  fullWidth> 
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.PinesLibres?.Entrada["1"].map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
    </TextField>
    </Grid>
    <Grid item xs={4} md={4} lg={4} >
    <TextField required label="Pin cerrado"  name="VD3" error={errorEntrada} select  fullWidth>
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.PinesLibres?.Entrada["1"].map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
    </Grid>

    <Grid item xs={12} md={12} lg={12}>
        <p>ingrese los pines de los motores de carga y descarga</p>
      </Grid> 

      <Grid item xs={6} md={6} lg={6}>
      <TextField required label="Motor Carga"  error={errorSalida} name="MC" select  fullWidth>
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.PinesLibres?.Salida["1"].map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
      <TextField required label="Motor Descarga"  error={errorSalida} name="MD" select  fullWidth>
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.PinesLibres?.Salida["1"].map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
      </Grid>
    </Grid>
    

    const autoSA=<Grid container>
        <Grid item xs={12} md={12} lg={12}>
        <p>ingrese los pines de la lectura de tanques</p>
      </Grid>

      <Grid item xs={6} md={6} lg={6}>
    <TextField required label="Tanque Carga" error={errorADS} name="TC"  select  fullWidth >
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.AdsLibres.map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
    </Grid>
    <Grid item xs={6} md={6} lg={6}>
    <TextField required label="Tanque Descarga" error={errorADS} name="TD" select  fullWidth >
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.AdsLibres.map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
    </Grid>

    </Grid>

const handleChangeTemp = (event) => {
  setTempAds(event.target.checked);
};
    const autoCA= <Grid container >
       <Grid item xs={12} md={12} lg={12}>
        <p>ingrese el pin para el sensor MQ3</p>
      </Grid> 
      <Grid item xs={12} md={12} lg={12}>
    <TextField required label="MQ-3 Pin" name="AL"  error={errorADS}  select  fullWidth >
    {rasps.map((r) => {
          if (r?.Id === raspId )
          {
            return (r?.AdsLibres.map((s)=>(
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )))
            }
        })}
       </TextField>
    </Grid>
    <Grid item xs={12} md={12} lg={12}>
        <p>ingrese Resistencia de sensado, inicial ratio y voltaje de funcionamiento</p>
      </Grid> 
    <Grid item xs={4} md={4} lg={4}>
    <TextField required label="MQ-3 RL Ω"   defaultValue={990} type="number" fullWidth /> 
    </Grid>
    <Grid item xs={4} md={4} lg={4}>
      <p>.</p>
    </Grid>
    <Grid item xs={4} md={4} lg={4}>
    <TextField required label="MQ-3 RS en aire Ω"   defaultValue={13478}  type="number" fullWidth /> 
    </Grid>
    
    </Grid>
    function rend(){
      if (tipoFermentador === "Manual")  {
        return (textManual)
      }
      else if (tipoFermentador === "Automatico sin alcohol"){
        return (<div>
          {textManual}
          {autoSA}
        </div>)
      }
      else if (tipoFermentador === "Automatico completo"){
        return (<div>
          {textManual}
          {autoSA}
          {autoCA}
        </div>)
      }

        
        
        
    }

    const addFermentadorPopUp= <Dialog open={addFermentador} onClose={handleaddFermentadorClose}>
    <Box component="form" onSubmit={FermentadorSubmit} noValidate>
    <DialogTitle>Agregar Fermentador</DialogTitle>
    <DialogContent>
      <DialogContentText>
       Llenar los datos para crear un nuevo Fermentador
      </DialogContentText>
        
        <TextField required  select name="type" id="type" onChange={cambiarTipo} defaultValue={tipoFermentador} label="Tipo de Fermentador"  helperText="Seleccione el tipo de fermentador " fullWidth variant="standard">
          <MenuItem  value="Solo registro">
          Solo Registro
          </MenuItem>
          <MenuItem  value="Manual">
                Manual
          </MenuItem>
          <MenuItem  value="Automatico sin alcohol">
          Automatico sin alcohol
          </MenuItem>
          <MenuItem  value="Automatico completo">
          Automatico completo
          </MenuItem>
        </TextField>

        <TextField  margin="dense" name="Id" id="id" defaultValue={1} label="Id Fermentador" type="number" 
        fullWidth variant="standard"
        InputProps={{ inputProps: { min: 1, max: 10 } }}/>
        <TextField  required select margin="dense" defaultValue={raspId} onChange={idRaspset} name="RaspId" id="idRasp" label="seleccione la Id de Raspberry" fullWidth variant="standard">
        {rasps.map((r) => (
          <MenuItem   key={r?.Id*11} value={r?.Id}>
          {r?.Id}
          </MenuItem>
        ))}
        </TextField>
        
        {rend()}
        <p>{(TempAds=== true ) ? ("Sensor thermistor"):("Sensor 1-W")} </p>
        <Switch checked={TempAds}  onChange={handleChangeTemp} > </Switch>
        {(TempAds=== true ) 
        ?(<TextField required label="Pin Sensor Temperatura" error={errorADS} name="TEMP" id="temp"  select  fullWidth >
          {rasps.map((r) => {
                if (r?.Id === raspId )
                {
                  return (r?.AdsLibres.map((s)=>(
                    <MenuItem key={s} value={s}>
                      {s}
                    </MenuItem>
                  )))
                  }
              })}
            </TextField>)
        :(  <TextField select  margin="dense" name="TEMP" id="temp" label="Temperatura" fullWidth variant="standard">
          {rasps.map((r) => (
            <MenuItem   key={r?.Id*12} value={r["1Wire"]}>
            {r["1Wire"]}
            </MenuItem>
          ))}
          </TextField>)}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleaddFermentadorClose}>Cancel</Button>
      <Button type="submit">Agregar</Button>
    </DialogActions>
    </Box>
  </Dialog>
  
  return (
    <>
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Id Fermentador</TableCell>
                    <TableCell align="left">Tipo de Fermentador</TableCell>
                    <TableCell align="left">Estado</TableCell>
                    <TableCell align="left">Ultimos Datos</TableCell>
                    <TableCell align="left">Pines</TableCell>
                    <TableCell align="left">Editar</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {fermentadores.map((user) => (
                
                <TableRow
                key={user?.id*10}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row"> {user?.id} </TableCell>
                  <TableCell align="left">{user?.tipo}</TableCell>
                  <TableCell align="left">{user?.estado}</TableCell>
                  <TableCell align="left">
                    <Typography>Alcohol :{user?.last?.Alcohol} %</Typography>
                    <Typography>Temp :{user?.last?.Temperatura} C </Typography>
                    <Typography>Acidez :{user?.last?.Acidez}% </Typography>
                    <Typography>Temp Alert :{user?.TempRef}% </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {Object.entries(user?.pines).map(([key,value])=>{
                      // console.log("debug keys ",key)
                      if (key !== "ValCar" && key !== "ValDes" ){
                        return (<Typography key={value+key}>{key}:{value} </Typography>)
                      }
                      else {
                        let llaveExp
                        if (key === "ValCar"){
                          llaveExp="ValvCarga[PWM,A,C]"
                        }
                        else{
                          llaveExp="ValvDescarga[PWM,A,C]"
                        }
                        return (<Typography key={value}>{llaveExp}:{value[0]},{value[1]},{value[2]} </Typography>)
                      }
                    })}
                    
                  </TableCell>
                  <TableCell align="left">
                      
                      <Grid container rowSpacing={2} >
                        <Grid item  xs={12} md={12} lg={6} >
                      { user?.tipo === "Programable completo"  &&  <Button onClick={calibrateMQ3} name={user?.id} color="warning" variant="contained" >Calibrar MQ3</Button>}
                      </Grid>
                      <Grid item  xs={12} md={12} lg={6} >
                      <Button onClick={deleteFermentador} name={user?.id} color="error" variant="contained" >Delete</Button>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6} >
                      <TextField defaultValue={user?.TempRef}  type="number"   InputProps={{ inputProps: { min: -10.0, max: 50.0,step:0.1 } }} 
                      onChange={(event) => {
                      setTempRefVal(event.target.value);
                      }}  label="Temp Ref"  />
                      </Grid>
                      <Grid item  xs={6} md={6} lg={6} >
                      <Button onClick={setTempRef} name={user?.id} color="warning" variant="contained" >Set Temp REF</Button>
                      </Grid>

                      </Grid>
                  </TableCell>
                </TableRow>

                
                
            ))}
            </TableBody>
        </Table>
      </TableContainer>
        {addFermentadorPopUp}
        
      <Grid Container rowSpacing={10}>
      <Grid item xs={12} md={12} lg={12}>
<p></p>
      </Grid>
        <Grid item xs={12} md={12} lg={12}>
              <Button variant="contained"  color="success"  onClick={handleaddFermentadorOpen}> Agregar Fermentador</Button>
        </Grid>
      </Grid>
      </>
  )
}