import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {createTheme, ThemeProvider } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link,useLocation } from "wouter";
import {useState,useEffect} from 'react'
import { Url } from '../Constants/global';

const theme = createTheme({
    palette: {
      neutral: {
        main: '#000000',
        contrastText: '#fff',
      },
    },
  });
export default function ButtonAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [location,navigate] = useLocation()
  const [user,setUser]=useState({})
  
  useEffect(() => {
    let token="Bearer " + localStorage.getItem("access_token");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': token }
  };
    fetch((Url+"/user"),requestOptions).then(
      res=>{
        console.log(res)
        if (res.status !== 200){
          navigate("/login")
          return false
        }
        else
        {
          return res.json()
        }
      }
    ).then(
      res=>{
        console.log("return de fetch user de resposnive bar ", res)
        setUser(res)
      }
      
    )
  }, [])
  
  
  
  
  
  
  
  
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    
  };
  const cerrarSesion =()=>{
    localStorage.setItem("access_token", "none")
    navigate('/login')
  }
  const handleClose = () => {
    
    setAnchorEl(null);
  };
  let cond;
  // Modificar la ruta a /fermentador o /almacenamiento para esas opciones
  if (location === "/fermentadorYano" || location === "/almacenamientoYano"){
    let aux = ["Configuracion","Agregar","Eliminar"]
     cond=aux.map ((a)=>
     <MenuItem  key ={aux.indexOf(a)}onClick={handleClose}>{a}</MenuItem>
     );

     
    

  }
  else 
  {
     cond =[]
  }
  return (

    <Box sx={{ flexGrow: 1 ,alignItems:'center',textAlign:'center'}}>
        <ThemeProvider  theme={theme}>
      <AppBar  position="static"  color="info">
        <Toolbar >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {cond}
              <MenuItem disabled  divider >{user?.Nombre} {user?.Apellido} </MenuItem>
              <MenuItem onClick={cerrarSesion}>Cerrar Sesion</MenuItem>
      </Menu>
          
          <Typography variant="h6"  component="div" sx={{ flexGrow: 1 , display:{xs:'none',sm:"flex",md:"flex",lg:"flex"}}}>
            <Link href="/fermentador">
            <Button color="inherit" >Fermentacion</Button>
            </Link>
          </Typography>

          <Typography variant="h6" component="div" sx={{ flexGrow: 10}}>
            <Link href="/">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <img src={require('../Images/sl.png')} alt="sl" width="100"/>
          </IconButton>
          </Link>

          </Typography >
          <Typography variant="h6"  component="div" sx={{ flexGrow: 0 ,display:{xs:'none',sm:"flex",md:"flex",lg:"flex"}}}>
            <Link href="/almacenamiento">
            <Button color="inherit" >Almacenamiento</Button>
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
    </Box>

    
  );
//   {/* <AppBar position="static">
//   <Toolbar variant="dense">
//     <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
//       <MenuIcon />
//     </IconButton>
//     <Typography variant="h6" color="inherit" component="div">
//       Photos
//     </Typography>
//   </Toolbar>
// </AppBar> */}
}