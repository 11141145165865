import {useState,useEffect} from 'react'
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Grid ,Button} from '@mui/material';
import {Url} from '../Constants/global'
import Checkbox from '@mui/material/Checkbox';


import 'dayjs/locale/es-us'


function DatePickerDay({aprobado, Reporte}) {
  let token="Bearer " + localStorage.getItem("access_token");
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': token }
};
    dayjs.locale('es-us')
    const [value, setValue] = useState(dayjs());
    const [ids,setIds]=useState([])
    const [value2, setValue2] = useState(dayjs());
    const[seleccion,setSeleccion]=useState("day");
    const [tipoGrafica,setTipoGrafica]=useState("Todo")
    const [fermentadorSeleccionado,setFermentadorSeleccionado]=useState(1)
    const [descargarReporte,setDescargarReporte]=useState(false)
    const handleChange = (newValue) => {
      setValue(newValue);
    };
    let api=Url+'/api/plot'
    let apiFull=api
    const handleChange2 = (newValue) => {
      setValue2(newValue);
    };

    useEffect(() => {
      if (Reporte === false){
      fetch((Url+"/api/getidsfermentadores"),requestOptions).then(
        res=>{
          if (res.status ===200){
            return res.json()
          }
        }
      ).then(
        res=>{
          setIds(res?.Ids)
        }
        
      )
    }}, [])
    

    const genReporte=()=>{
      let postContent=JSON.stringify({
        Date1:  value.format('DD-MM-YYYY'),
        Date2: value2.format('DD-MM-YYYY'),
        Registro:tipoGrafica,
        Tipo:seleccion


      });

      const requestOptions2 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body:postContent
    };
      fetch((Url+"/api/report"),requestOptions2).then(
        res=>{
          // console.log("RESPUESTA ES blob:",res.blob())
          if (res.status !== 200){
            alert("error al generar el reporte")
            return false
            
          }
        return res.blob()}).then(
          blob=>{
            require('dayjs/locale/es-us')
             let titulo="Reporte "
             if (seleccion === "day"){
                titulo=titulo + "diario " +value.locale('es-us').format("DD_MMM_YYYY")
             }
             else if (seleccion === "month"){
              titulo=titulo + "mensual de " +value.locale('es-us').format("MMM_YYYY")
             }
             else{
              titulo=titulo + "del " +value.locale('es-us').format("DD_MMM_YYYY") + " al " + value2.locale('es-us').format("DD_MMM_YYYY")
             }
             titulo=titulo+".pdf"
             aprobado(blob,titulo,descargarReporte)
             }//nombre y descarga}
            
            
        )
    }
    
    const handleSeleccion = (event)=>{
      setSeleccion(event.target.value);
  };

  const handleTipoGrafica= (event)=>{
    setTipoGrafica(event.target.value);
  };

  const handleFermentador= (event)=>{
    setFermentadorSeleccionado(event.target.value);
};

const genGraph=()=>{
  console.log("fecheando")
  let fetch_link = ""

  if (seleccion === "day"){
    fetch_link=apiFull+"/"+fermentadorSeleccionado+"/"+seleccion+"/"+value.format('DD-MM-YYYY')
  }
  else if (seleccion === "range"){
    fetch_link=apiFull+"/"+fermentadorSeleccionado+"/"+seleccion+"/"+value.format('DD-MM-YYYY')+"/to/"+value2.format('DD-MM-YYYY')+"/"+tipoGrafica
  }
  else if (seleccion === "month"){
    fetch_link=apiFull+"/"+fermentadorSeleccionado+"/"+seleccion+"/"+value.format('MM-YYYY')+"/"+tipoGrafica
  }
  fetch((fetch_link),requestOptions).then(
  res=>{
    if (res.status ===200){
      return res.json()
    }
  }
  ).then(
    res=>{
      console.log("la respuesta es ",res)
      aprobado(res)
    }
    
  )
}

const handleDescargarReporte=(event)=>{
  setDescargarReporte(event.target.checked)
}

  var seleccionFecha = <DesktopDatePicker
  label="Seleccione el dia"
  value={value}
  inputFormat="DD-MMMM-YYYY"
  // helperText="Start from year selection"
  minDate={new Date('2023')}
  maxDate={new Date('2050')}
  onChange={handleChange}
  renderInput={(params) => <TextField {...params} />}
/>
  if (seleccion === "day"){
    seleccionFecha=<DesktopDatePicker
                label="Seleccione el dia"
                value={value}
                inputFormat="DD-MMMM-YYYY"
                // helperText="Start from year selection"
                minDate={new Date('2023')}
                maxDate={new Date('2050')}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
  }
  else if (seleccion === "month"){ 
    seleccionFecha=<DesktopDatePicker

  views={['year', 'month']}
  label="Año y Mes"
  minDate={new Date('2023')}
  inputFormat="MMMM-YYYY"
  maxDate={new Date('2050')}
  value={value}
  onChange={setValue}
  renderInput={(params) => <TextField {...params} helperText={null} />}
/>
  }
  else if (seleccion === "range"){
    
    seleccionFecha= 
<div>
<DesktopDatePicker
                label="Seleccione el fecha de inicio"
                value={value}
                inputFormat="DD-MMMM-YYYY"
                minDate={new Date('2023')}
                maxDate={new Date('2050')}
                onChange={handleChange}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
              />
<DesktopDatePicker
                label="Seleccione el fecha de Fin"
                value={value2}
                minDate={new Date('2023')}
                maxDate={new Date('2050')}
                inputFormat="DD-MMMM-YYYY"
                onChange={handleChange2}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
              />
</div>
  }
    return (
      <>
    <Grid
    container
    rowSpacing={3}>
      {Reporte === false && 
      <Grid item xs={12} md={12} lg={12}>
        
        <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={fermentadorSeleccionado}
        label="Fermentador "
        onChange={handleFermentador}
        fullWidth
        >
          {ids.map( (ID) => (
            <MenuItem key={ID} value={ID}>Fermentador {ID}</MenuItem>
          ))}
         
 
        </Select>
        
      </Grid>
      }
      {Reporte === true && <Grid item xs={12} md={12} lg={12}>
        <p>Descargar PDF </p> 
        <Checkbox
      checked={descargarReporte}
      onChange={handleDescargarReporte}
      inputProps={{ 'aria-label': 'controlled' }}
    />
        </Grid>} 
      <Grid item xs={12} md={12} lg={12}>
        <Select
        labelId="Seleccion"
        id="demo-simple-select"
        value={seleccion}
        label="Tipo de seleccion"
        onChange={handleSeleccion}
        fullWidth
        >
          <MenuItem value={"day"}>Diario</MenuItem>
          <MenuItem value={"month"}>Mensual</MenuItem>
          <MenuItem value={"range"} >Rango de Fechas</MenuItem>
        </Select>
      </Grid>
      {(seleccion !== "day"|| Reporte === true)? (
      <Grid item xs={12} md={12} lg={12}>
        <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={tipoGrafica}
        label="Tipo de Grafica"
        onChange={handleTipoGrafica}
        fullWidth
        >
          <MenuItem value={"Todo"}>Todos los registros</MenuItem>
          <MenuItem value={"NoRutina"} >Registros Manuales</MenuItem>
          <MenuItem value={"Control"}>Solo Controles</MenuItem>
          <MenuItem value={"Carga"} >Solo Cargas</MenuItem>
          <MenuItem value={"Descarga"} >Solo Descargas</MenuItem>
          <MenuItem value={"Rutina"} >Solo Registros automaticos</MenuItem>
          
        </Select>
      </Grid>) : (<p></p>)}

      <Grid item>

          <LocalizationProvider adapterLocale={dayjs.locale('es-us')} dateAdapter={AdapterDayjs}>
            {seleccionFecha}
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button variant="contained" color="success"  fullWidth onClick={(Reporte ===true)?(genReporte): (genGraph)} >
          Generar {(Reporte ===true)?("Reporte"): ("Grafica")}
          </Button>
        </Grid>
    </Grid>
    

    
    </>
    )
}

export default DatePickerDay