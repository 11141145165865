
import {Grid,Button,Box,Typography,DialogContentText,DialogTitle,Dialog,DialogContent,TextField} from '@mui/material'
import '../App.css';
import '../css/Tanque.css'
import {useState,useEffect} from 'react'
import {Url} from '../Constants/global'
import Numpad from './Numpad';
let api = Url
export default function TanqueAlmacen(p){
  const[aD,setAD]=useState(0)
  const [acidez, setAcidez]=useState(5)
  const [acidezDialog, setAcidezDialog] = useState(false)
  const [diluirDialog, setDiluirDialog] = useState(false)
  const [overContent,setOverContent]=useState(false)
  const [msg,setMsg]=useState("")
  const [agua,setAgua]=useState(0)

  const [acidezDeseadaT,setacidezDeseadaT]=useState(5.0)
  let token="Bearer " + localStorage.getItem("access_token");
    const fetOp = (event)=>{
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify({ Id: p?.Id,
                               Acidez:acidez,
                              AcidezDeseada:acidezDeseadaT,
                              Contenido:p?.Contenido
                            })
    };
      fetch(api+"/api/tanque/"+p?.Id+"/reg/"+event.target.name,requestOptions
      ).then( res=>res.json(),e =>{
        console.log("Obtención fallida", e);
        alert("registro fallido")
      }
    ).then(res=>{
      alert(res?.msg)
      
    })
   

    }

    let regAcidez = (n,b,dat,t)=>{
      console.log("acidez: ",n ,"obs:",b)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify({ Acidez: n, Id: p?.Id,})
    };
      fetch(api+"/api/tanque/"+p?.Id+"/reg/acidez",requestOptions
      ).then( res=>res.json(),e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")
      }
    ).then(res=>{
      
  
      // variables=res.variables
      p.reg(p?.Id,res?.tanque)
      alert(res?.msg)
      
    })
  
      acidezClose()
    }

    let regAcidezDes = ()=>{
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify({ Acidez: p?.Acidez, Id: p?.Id,Contenido:p?.Contenido,AcidezDeseada:acidezDeseadaT})
    };
      fetch(api+"/api/tanque/"+p?.Id+"/reg/agua",requestOptions
      ).then( res=>res.json(),e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")
      }
    ).then(res=>{
      
      console.log("el response es ",res)
      setOverContent(res.OC)
      setMsg(res.st)
      setAgua(res.Agua)
      if (res?.msg !== undefined){
        alert(res?.msg)
      }
  
      // variables=res.variables
      
      
    })
  
    }
    const acidezOpen = () => {
      setAcidezDialog(true);
    };
    const acidezClose = () => {
      setAcidezDialog(false);
    };
    const diluirOpen = () => {
      setDiluirDialog(true);
    };
    const diluirClose = () => {
      setDiluirDialog(false);
    };

    const fetDiluir = (n,b,dat,t)=>{
      
      
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body: JSON.stringify({ cantidad: n, Id: p?.Id,paro:false})
    };
      fetch(api+"/api/tanque/mqtt/diluir",requestOptions
      ).then( res=>res.json(),e =>{
        console.log("Obtención fallida", e);
        alert("Obtención de datos fallida")
      }
    ).then(res=>{
      
  
      
      
      alert(res?.msg)
      
    })
  
      diluirClose()


    }

    const dialogAcidezComponent =<Dialog
  open={acidezDialog}
  onClose={acidezClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  
>
  <DialogTitle id="alert-dialog-title">
    {"Registro de acidez"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
     Seleccione el % de acido acetico para registrar
    </DialogContentText>
    <Numpad regAlcohol={regAcidez} esAlcohol={false} esTanque={true}></Numpad>
    <Grid container >
    <Grid item xs={12} md={12} lg={12}>.</Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button variant="contained"  fullWidth onClick={acidezClose}>
          Cerrar
        </Button>
      </Grid>
    </Grid>
  </DialogContent>
  
</Dialog>





const dialogDiluirComponent =<Dialog
  open={diluirDialog}
  onClose={diluirClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">
    {"Ingrese cantidad de agua a diluir"}
  </DialogTitle>
  <DialogContent>
    <Numpad regAlcohol={fetDiluir} esAlcohol={false} esTanque={true}></Numpad>
    <Grid container >
    <Grid item xs={12} md={12} lg={12}>.</Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button variant="contained"  fullWidth onClick={diluirClose}>
          Cerrar
        </Button>
      </Grid>
    </Grid>
  </DialogContent>
  
</Dialog>

    return (
        <Grid item xs={12} md={4} lg={2} sx={{textAlign:'center',alignItems:'center'}}>
              <div className="containerTanque">
              <img src={require('../Images/Tanque.png')} alt="Tanque Almacenamiento " width="250" className={((Number(p?.Contenido))>=(Number(p?.Volumen)-(Number(p?.Volumen)*0.01)))?("invert"):("")}/>
              <div className='centered-tan'>
                <h1 style={{color:((Number(p?.Contenido))>=(Number(p?.Volumen)-(Number(p?.Volumen)*0.01)))?("black"):("white")}} >{p?.Volumen} L</h1>
                <Box>
                                <Box sx={{ borderRadius: '10px',m: 1,backgroundColor:"#504f4f" }} >
                                  <Typography  variant="h5" sx={{ fontWeight: 200}} > Cont: {p?.Contenido}L</Typography>
                                </Box>
                                <Box sx={{ borderRadius: '10px',m: 1,backgroundColor:"#504f4f" }} >
                                  <Typography  variant="h6" sx={{ fontWeight: 200}} > {p?.Tipo} </Typography>
                                </Box>
                                <Box sx={{ borderRadius: '10px',m: 1,backgroundColor:"#504f4f" }} >
                                  <Typography  variant="h6" sx={{ fontWeight: 200}} > Acidez: {p?.Acidez}%</Typography>
                                </Box>
                                <Box sx={{ borderRadius: '10px',m: 1,backgroundColor:"#504f4f" }} >
                                  <Typography  variant="h6" sx={{ fontWeight: 200}} > Lote: {p?.Lote} </Typography>
                                </Box>
                                <Box sx={{ borderRadius: '6px',m: 1,backgroundColor:"#504f4f" }} >
                                  <Typography  variant="h7" sx={{ fontWeight: 200}} > Altura: {p?.Altura} </Typography>
                                </Box>
                </Box>
              </div>
              </div>
            <Grid container  rowSpacing={2} columnSpacing={1}>
                <Grid item xs={6} md={6} lg={6}>
                    <Button variant="contained"  name="altura" fullWidth onClick={fetOp}> Set Altura</Button>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button variant="contained"  name="acidez" fullWidth onClick={acidezOpen}> Set Acidez</Button>
                    {dialogAcidezComponent}
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                <TextField value={acidezDeseadaT}  type="number" sx={{ input: { color: 'white' },
                                                                      label:{ color: '#cbd6e2' }}}  
                      InputProps={{ inputProps: { min: 0.0, max: 15.0,step:0.1 } }} 
                      onChange={(event) => {
                      setacidezDeseadaT(event.target.value);
                      }}  label="Acidez deseada"  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button variant="contained"  name="agua"  fullWidth onClick={regAcidezDes}> Calcular</Button>
                  
                </Grid>
                
                <Grid item xs={12} md={12} lg={12}>
                    <Button variant="contained" fullWidth color='error' onClick={diluirOpen} > Diluir</Button>
                    {dialogDiluirComponent}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Button variant="contained" fullWidth name="lote" color='warning' onClick={fetOp}> Set Lote</Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {msg!== "" && <p style={{color:(overContent===true)?("red"):("white")}}>{msg}</p>}
                    {msg!== "" &&<p style={{color:(overContent===true)?("red"):("white")}}>Agua a agregar para acidez de {acidezDeseadaT}% : {agua}L</p>}
                    {msg!== "" &&  <Button variant="contained"  color='warning' onClick={()=>{
                      setMsg("")
                      setAgua(0)
                      setOverContent(false)
                      
                    }}> Borrar</Button>}

                </Grid>
            </Grid>


              

              </Grid>
    )
}