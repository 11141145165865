import {Grid,Box} from '@mui/material'
import '../App.css';
import ResponsiveAppBar from './ResposiveAppBar'
import Typography from '@mui/material/Typography';

function defaultPage() {

    return (
        <div className='bg'>
         <div>
         <ResponsiveAppBar>
           
         </ResponsiveAppBar>
          .
         </div>
         <Box sx={{width: "100%",
           // height: "200vh",
           backgroundColor:"#282c34",
           alignItems: "center",
           justifyContent: "center",
           textAlign:"center",
           color: "white",
           display:"flex",
           flexDirection: 'column'}}>
 <Typography variant="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h1" gutterBottom>
        No existe la pagina solicitada
      </Typography>
           </Box>
           </div>
     )



}

export default defaultPage