import {Box,Grid} from '@mui/material'
import '../App.css';
import { useEffect ,useState} from 'react';
import { Url } from '../Constants/global';
import { useLocation } from 'wouter';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

export default function AdminAlmacen(){
    let token="Bearer " + localStorage.getItem("access_token");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': token }
  };
  const [,navegate]=useLocation()
    const [tanques,setTanques]=useState([])
    const [addTanque,setAddTanque]=useState(false)
    const [editTanque,setEditTanque]=useState(false)
    const [tanqueToEdit,setTanqueToEdit]=useState({})

    useEffect(() => {
      fetch((Url+"/api/tanque"),requestOptions).then(
        res=>{
            if (res.status === 205){
              navegate("/")
            }
            return res.json()
        }
      ).then(
            res=>{
                console.log(res)
                setTanques(res["Tanques"])
            }
      )
    
    }, []) //eslint-disable-line 
  
    function deleteTanque(e){
      if (window.confirm("Esta seguro de eliminar el tanque " + e.target.name) ){
        let postContent=JSON.stringify({
          Id:  e.target.name
        });
        let requestOptions2 = {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', 'Authorization': token },
          body:postContent
      };
        
      fetch((Url+"/api/tanque"),requestOptions2).then(
        res=>{
          if (res.status === 200){
            window.location.reload(false);
          }
          return res.json();
        }

      ).then(
          res=>{
            alert(res?.msg)
          }
      )
      }
        
      
      
    }
    
    const Edit = (event)=>{
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let postContent=JSON.stringify({
        Id:tanqueToEdit?.Id,
        Tipo:data.get('Type'),
        Volumen:data.get('Volumen'),
        Diametro:data.get('Diametro'),
        Lote:data.get('Lote'),
        // Lote:data.get('Lote'),
        // Acidez:data.get('Acidez'),
      });

      let requestOptions2 = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body:postContent
    };
      // fechear
      fetch((Url+"/api/tanque"),requestOptions2).then(
        res=>{
          if (res.status ===200){
            
            handleEditTanqueClose()
            
          }

          return res.json();
        }

      ).then(
          res=>{
            alert(res?.msg)
          }
      )
      

        
    }

    const Tanque = (event)=>{
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let postContent=JSON.stringify({
        Id: data.get('Id'),
        IdNode: data.get('IdNode'),
        Tipo:data.get('Type'),
        Volumen:data.get('Volumen'),
        Diametro:data.get('Diametro'),
      });
      let requestOptions2 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        body:postContent
    };
      // fechear
      fetch((Url+"/api/tanque"),requestOptions2).then(
        res=>{
          if (res.status ===200){
            
            handleAddTanqueClose()
            
          }

          return res.json();
        }

      ).then(
        
          res=>{
            console.log("response es almacenamiento",res)
            alert(res?.msg)
          }
      )
    }
    const handleAddTanqueOpen = () => {
      setAddTanque(true);
    };
  
    const handleAddTanqueClose = () => {
      setAddTanque(false);
    };
    const handleEditTanqueOpen = () => {
      setEditTanque(true);
    };
  
    const handleEditTanqueClose = () => {
      setEditTanque(false);
    };
    const addTanquePopUp= <Dialog open={addTanque} onClose={handleAddTanqueClose}>
    <Box component="form" onSubmit={Tanque} noValidate>
    <DialogTitle>Agregar Tanque</DialogTitle>
    <DialogContent>
      <DialogContentText>
       Llenar los datos para crear un nuevo Tanque
      </DialogContentText>
        <TextField autoFocus required margin="dense" name="Id" id="Id"label="Id de Tanque" type="number" fullWidth variant="standard"/>
        <TextField required margin="dense" name="IdNode" id="IdNode"label="Id de ESP32" type="number" fullWidth variant="standard"/>
        <TextField required  margin="dense" name="Diametro" id="Diametro" label="Diametro (M)" type="number" fullWidth variant="standard"/>
        <TextField required  select name="Type" id="Type" defaultValue="Vinagre de Alcohol" label="Tipo de Viangre"  helperText="Seleccione el tipo de Vinagre" fullWidth variant="standard">
          <MenuItem  value="Vinagre de Alcohol">
                Alcohol
          </MenuItem>
          <MenuItem  value="Vinagre de Manzana">
                Manzana
          </MenuItem>
          <MenuItem  value="Vinagre de Vino">
                Vino
          </MenuItem>
        </TextField>
        <TextField  margin="dense" name="Volumen" id="Volumen" label="Maxima capacidad de Tanque" type="number" fullWidth variant="standard"/>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleAddTanqueClose}>Cancel</Button>
      <Button type="submit">Agregar</Button>
    </DialogActions>
    </Box>
  </Dialog>
  
    const editUserPopUp= <Dialog open={editTanque} onClose={handleEditTanqueClose}>
    <Box component="form" onSubmit={Edit} noValidate>
    <DialogTitle>Editar Tanque</DialogTitle>
    <DialogContent>
      <DialogContentText>
       Llenar los datos para editar el Tanque {tanqueToEdit?.Id} 
      </DialogContentText>
        <TextField margin="dense" name="Volumen" defaultValue={tanqueToEdit?.Volumen} id="Volumen" label="Maxima capacidad de Tanque" type="number" fullWidth variant="standard"/>
        <TextField select name="Type" id="Type" label="Tipo de Vinagre" defaultValue={tanqueToEdit?.Tipo}  helperText="Seleccione el tipo de vinagre" fullWidth variant="standard">
          <MenuItem  value="Vinagre de Alcohol">
                Alcohol
          </MenuItem>
          <MenuItem  value="Vinagre de Manzana">
                Manzana
          </MenuItem>
          <MenuItem  value="Vinagre de Vino">
                Vino
          </MenuItem>
        </TextField>

        <TextField  margin="dense" defaultValue={tanqueToEdit?.Diametro} name="Diametro" id="Diametro" label="Diametro" type="number" fullWidth variant="standard"/>
        <TextField  margin="dense" name="Lote" id="Lote" label="Lote" type="name" defaultValue={tanqueToEdit?.Lote} fullWidth variant="standard"/>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleEditTanqueClose}>Cancel</Button>
      <Button type="submit">Modificar</Button>
    </DialogActions>
    </Box>
  </Dialog>
  return (
    <>
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Tanque N°</TableCell>
                    <TableCell align="left">Tipo de vinagre</TableCell>
                    <TableCell align="left">Capacidad (L)</TableCell>
                    <TableCell align="left">Acidez %</TableCell>
                    <TableCell align="left">Lote</TableCell>
                    <TableCell align="left">Diametro (M)</TableCell>
                    <TableCell align="left">Altura (M)</TableCell>
                    <TableCell align="left">Editar</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {tanques.map((t) => (
                
                <TableRow
                key={t?.Id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row"> {t?.Id} </TableCell>
                  <TableCell align="left">{t?.Tipo}</TableCell>
                  <TableCell align="left">{t?.Volumen}L</TableCell>
                  <TableCell align="left">{t?.Acidez}%</TableCell>
                  <TableCell align="left">{t?.Lote}</TableCell>
                  <TableCell align="left">{t?.Diametro}M</TableCell>
                  <TableCell align="left">{t?.Altura}M</TableCell>
                  <TableCell align="left">
                      <Button variant="outlined" onClick={()=>{ handleEditTanqueOpen();setTanqueToEdit(t);}}>Edit</Button> 
                      <b></b>
                      <Button onClick={deleteTanque} name={t?.Id} color="error" variant="contained">Delete</Button>
                  </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
      </TableContainer>
        {addTanquePopUp}
        {editUserPopUp}
      <Grid Container rowSpacing={10}>
      <Grid item xs={12} md={12} lg={12}>
<p></p>
      </Grid>
        <Grid item xs={12} md={12} lg={12}>
              <Button variant="contained"  color="success"  onClick={handleAddTanqueOpen}> Agregar Tanque</Button>
        </Grid>
      </Grid>
      </>
  )
}